import React from "react";
import { Container } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";

import "./CheckoutConfirmation.scss";

interface Props {
  orderId: string;
}

export const CheckoutConfirmation = (props: RouteComponentProps<Props>) => {
  return (
    <div className="CheckoutConfirmation">
      <Container>
        <div className="CheckoutConfirmation__head">
          <h1>Thank you for your order!</h1>
          <p>
            Your order is being processed, you will be notified via your email
            for further details!
          </p>
        </div>
      </Container>
    </div>
  );
};
