import React from "react";
import { Container } from "reactstrap";
import { Icon } from "../../components/shared/Icon/Icon";
import { Link } from "react-router-dom";
import { fields } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { GET_FOOTER_OPTIONS } from "../../queries/generalQueries";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import "./Footer.scss";

export const Footer = () => {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GET_FOOTER_OPTIONS);
  const footer = data?.generalOptions?.generalOptions?.footer;
  return (
    <div className="Footer">
      <Container>
        <div className="Footer__items">
          <ul className="Footer__list">
            <li className="Footer__list-head">{t(fields.otherLinks)}</li>
            <li>
              <Link to="/page/transporti">{t(fields.transport)}</Link>
            </li>
            <li>
              <Link to="/contact">{t(fields.contact)}</Link>
            </li>
            <li>
              <Link to="/page/kushtet-e-blerjes">
                {t(fields.termsOfPurchase)}
              </Link>
            </li>
            <li>
              <Link to="/page/politikat-e-privatesise">
                {t(fields.privacyPolicy)}
              </Link>
            </li>
          </ul>
          <HandleLoadingState
            loadingPlaceholder={<div>loading...</div>}
            loading={loading}
          >
            <ApolloErrorGuard error={error}>
              <ul className="Footer__list">
                <li className="Footer__list-head">Social Media</li>
                <li style={{ display: "flex" }}>
                  {footer?.facebook && (
                    <a
                      href={footer.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="facebook" />
                    </a>
                  )}
                  {footer?.instagram && (
                    <a
                      href={footer.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-3"
                    >
                      <Icon icon="instagram" />
                    </a>
                  )}
                  {footer?.youtube && (
                    <a
                      href={footer.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-3"
                    >
                      <Icon icon="youtube" />
                    </a>
                  )}
                  {footer?.twitter && (
                    <a
                      href={footer.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-3"
                    >
                      <Icon icon="twitter" />
                    </a>
                  )}
                </li>
              </ul>

              <ul className="Footer__list">
                <li className="Footer__list-head">{t(fields.contact)}</li>
                {footer?.phone && (
                  <li>
                    <a
                      href={`tel:${footer.phone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {footer.phone}
                    </a>
                  </li>
                )}
                {footer?.email && (
                  <li>
                    <a
                      href={`mailto:${footer.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {footer.email}
                    </a>
                  </li>
                )}
              </ul>
            </ApolloErrorGuard>
          </HandleLoadingState>
        </div>
      </Container>
      <div className="Footer__copyright">
        <span>Copyright © Sobazaar {new Date().getFullYear()}</span>
      </div>
    </div>
  );
};
